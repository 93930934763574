import {
  SET_MY_GROUPS,
  ADD_NEW_POST,
  SET_POSTS,
  DELETE_COMMENT,
  DELETE_POST,
  SET_COMMENTS,
  UPDATE_GROUPS,
  SET_TYPE_FILTER,
  SET_LOCATION_FILTER,
  ADD_PENDING_GROUP,
} from './actionTypes';
import {
  APPROVE_ADMIN_GROUP,
  DENY_ADMIN_GROUP,
} from 'features/admin/components/GroupsAdmin/actions';
import { AUTH_LOGOUT } from 'features/auth/actionTypes';

const initialState = {
  groups: [],
  myGroups: null,
  pendingGroups: [],
  posts: [],
  comments: [],
  type: '',
  location: '',
};

const groups = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NEW_POST:
      return {
        ...state,
        posts: [...new Set([action.payload].concat(state.posts))],
      };
    case SET_POSTS:
      return {
        ...state,
        posts: action.payload,
      };
    case SET_COMMENTS:
      return {
        ...state,
        comments: [...new Set(state.comments.concat(action.payload))],
      };
    case DELETE_COMMENT:
      return {
        ...state,
        comments: state.comments.filter(cid => cid !== action.payload),
      };
    case DELETE_POST:
      return {
        ...state,
        posts: state.posts.filter(pid => pid !== action.payload),
      };
    case SET_MY_GROUPS:
      return { ...state, myGroups: action.payload };
    case UPDATE_GROUPS:
      return {
        ...state,
        groups: [...new Set(state.groups.concat(action.payload))],
      };
    case ADD_PENDING_GROUP:
      return {
        ...state,
        pendingGroups: [...new Set(state.pendingGroups.concat(action.payload))],
      };
    case APPROVE_ADMIN_GROUP:
    case DENY_ADMIN_GROUP:
      return {
        ...state,
        pendingGroups: state.pendingGroups.filter(id => id !== action.payload),
      };
    case SET_TYPE_FILTER:
      return {
        ...state,
        type: action.payload,
      };
    case SET_LOCATION_FILTER:
      return {
        ...state,
        location: action.payload,
      };
    case AUTH_LOGOUT:
      return {
        ...state,
        myGroups: null,
        type: '',
        location: '',
      };
    default:
      return state;
  }
};

export default groups;
