// Fetch and load
export const FETCH_PRO_SCRIPTS = 'fetch_pro_scripts';
export const LOADED_PRO_SCRIPTS = 'loaded_pro_scripts';
export const LOADED_FILTERED_PRO_SCRIPTS = 'loaded_filtered_pro_scripts';
export const LOADED_SHARED_PRO_SCRIPTS = 'loaded_shared_pro_scripts';
export const LOADED_PRO_LISTS = 'loaded_pro_lists';

// Update list
export const UPDATE_PRO_LISTS = 'update_pro_lists';

// Pro Filters
export const SET_PRO_SCRIPTS_FILTERS = 'set_pro_scripts_filters';

// Saving
export const ADD_SAVED_PRO_SCRIPT = 'add_saved_pro_script';
export const REMOVE_SAVED_PRO_SCRIPT = 'remove_saved_pro_script';

// Removing
export const ADD_REMOVED_PRO_SCRIPT = 'add_removed_pro_script';
export const REMOVE_REMOVED_PRO_SCRIPT = 'remove_removed_pro_script';

// Remove Now
export const REMOVE_NOW_PRO_SCRIPT = 'remove_now_pro_script';

// Save
export const SAVE_PRO_SCRIPTS = 'save_pro_scripts';

// Cancel
export const CANCEL_PRO_SCRIPTS = 'cancel_pro_scripts';

// Blacklist
export const SET_BLACKLIST = 'set_blacklist';
