import { OPEN_MODAL, CLOSE_MODAL } from './actionTypes';
import { AUTH_LOGOUT } from 'features/auth/actionTypes';

const initialState = {
  isOpen: false,
  wasOpen: false,
  modalName: null,
  modalProps: {},
};

const modal = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        isOpen: true,
        wasOpen: state.isOpen,
        modalName: action.modalName,
        modalProps: action.modalProps,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        isOpen: false,
        wasOpen: false,
      };
    case AUTH_LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default modal;
