import React from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import { color, unit, zIndex } from 'core/v2/ui';

import IconInfoRaw from 'generic/Icon/minis/Icon_info';

let Tooltip = props => <ReactTooltip type="light" {...props} />;

Tooltip = styled(Tooltip)`
  padding: ${unit[3]} ${unit[2]};
  box-shadow: 0 8px 28px 0 rgba(0, 0, 0, 0.2);
  color: ${color.grey700};
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  z-index: ${zIndex.tooltip};
`;

export const IconInfo = styled(IconInfoRaw)`
  margin-left: ${unit[2]};
  cursor: pointer;

  > path {
    fill: ${color.green400};
  }
`;

export default Tooltip;
