import styled, { css } from 'styled-components';
import is, { isNot } from 'styled-is';
import { Link } from 'react-router-dom';

import { color, unit, rounded, zIndex } from 'core/v2/ui';

import IconOptionsRaw from 'generic/Icon/base24/Icon_options';

export const FlyoutNavStyled = styled.div`
  display: none;
  justify-content: flex-end;
  position: absolute;
  z-index: 1;

  ${is('active')`
    display: flex;
  `};

  ${is('fit')`
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `};

  ${is('row')`
    top: 0;
    right: 8px;
    z-index: ${zIndex.tooltip};
  `};
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.75;
  background-color: ${color.blue900};
  border-radius: ${rounded.lg};
`;

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  flex-basis: 200px;
  min-width: 170px;
  position: relative;
  background-color: ${color.white};
  border-radius: 0 4px ${rounded.lg} 0;
  box-shadow: 0 8px 28px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: normal;

  ${is('navAutoHeight')`
    align-self: flex-start;
    border-bottom-right-radius: 0;
  `};

  ${is('largeButton')`
    border-radius: 0;
  `};
`;

export const FlyoutContent = styled.div`
  flex: 1;
  padding: ${unit[1]} ${unit[3]};
`;

export const FlyoutFooter = styled.div`
  display: flex;
  flex-basis: 60px;
  padding-left: ${unit[3]};
  padding-right: ${unit[3]};
  border-top: 1px solid ${color.grey};
`;

export const flyoutActionCss = css`
  display: flex;
  align-items: center;
  flex: 1;
  min-height: 44px;
  color: ${color.blue400};
  font-size: 14px;
  line-height: 19px;
  font-weight: bold;
  text-decoration: none;
  text-transform: none;

  &:hover {
    color: ${color.blue700};
  }
`;

export const FlyoutLink = styled(Link)`
  ${flyoutActionCss};
`;

export const FlyoutAction = styled.div.attrs({
  role: 'button',
})`
  ${flyoutActionCss};

  cursor: pointer;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1;

  ${is('absolute')`
    position: absolute;
    top: 6px;
    right: 0;
  `};

  ${is('largeButton')`
    top: 14px;
  `};
`;

export const IconOptions = styled(IconOptionsRaw)`
  > path {
    fill: ${color.white};
  }

  ${is('dark')`
    > path {
      fill: ${color.blue400};
    }
  `};

  ${isNot('active')`
    ${Button}:hover & {
      opacity: 0.7;
    }
  `};

  ${is('active')`
    opacity: 1;

    > path {
      fill: ${color.blue400};
    }
  `};
`;
