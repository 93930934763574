import { SET_MY_PRO_REVIEWS, SET_EARNINGS } from 'features/proReviewer/actionTypes';
import { AUTH_LOGOUT } from 'features/auth/actionTypes';

const initialState = {
  myProReviews: [],
  earnings: {
    totalEarned: 0,
    totalOwed: 0,
  },
};

const proReviewer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MY_PRO_REVIEWS:
      return { ...state, myProReviews: action.payload };
    case SET_EARNINGS:
      return { ...state, earnings: action.payload };
    case AUTH_LOGOUT:
      return { ...state, ...initialState };
    default:
      return state;
  }
};

export default proReviewer;
