// --- Groups ---
export const SET_MY_GROUPS = 'set_my_groups';
export const UPDATE_GROUPS = 'update_groups';
export const SET_TYPE_FILTER = 'set_type_filter';
export const SET_LOCATION_FILTER = 'set_location_filter';
export const ADD_PENDING_GROUP = 'add_pending_group';

// --- Posts ---
export const SET_POSTS = 'set_posts';
export const SET_COMMENTS = 'set_comments';
export const DELETE_COMMENT = 'delete_comment';
export const DELETE_POST = 'delete_post';
export const ADD_NEW_POST = 'add_new_posts';
