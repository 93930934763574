import React from 'react';

import Flex from 'generic/Flex/Flex';
import Heading from 'generic/Heading/Heading';

const AuthBoundry = () => (
  <Flex center column fit>
    <Heading>Error while loading Writers Spotlight, refresh the page.</Heading>
  </Flex>
);

export default AuthBoundry;
