/**
 * Check  ifthe actions carry entities and update the targeted reducers if it does
 * Will dispatch data to the right reducers by dispatching actions of corresponding types
 *  e.g: users_entities, scripts_entities
 * @param {*} state
 */
const entitiesMiddleware = ({ dispatch }) => next => action => {
  if (action.entities) {
    // --- OLD way  @TODO remove once refactored
    // Object.keys(action.entities).forEach(key => {
    //   dispatch({ type: `${key}_entities`, payload: { byIds: action.entities[key] } });
    // });
    // --- end OLD way

    dispatch({
      type: `ENTITIES_SET`,
      payload: action.entities,
    });
  }
  if (action.deleteId && action.entityName) {
    dispatch({
      type: `ENTITIES_DELETE`,
      payload: { entityId: action.deleteId, entityName: action.entityName },
    });
  }
  next(action);
};

export default entitiesMiddleware;
