export const THREADS_SET_THREADS = 'threads_set_threads';
export const THREADS_ADD_THREAD = 'threads_add_thread';
export const THREADS_SET_MESSAGES = 'threads_set_messages';
export const THREADS_ADD_MESSAGE = 'threads_add_message';

export const THREADS_ENTITIES = 'threads_entities';
export const THREADS_SET_ENTITIES = 'threads_set_entities';
export const THREADS_OPEN_CHAT = 'threads_open_chat';
export const THREADS_CLOSE_CHAT = 'threads_close_chat';
export const THREADS_SET_ACTIVE = 'threads_set_active';
export const THREADS_UPDATE = 'threads_update';
// Optimistic UI:
export const THREADS_MARK_AS_READ = 'threads_mark_as_read';
export const THREADS_MARK_AS_READ_UNDO = 'threads_mark_as_read_undo';
export const THREADS_NEW_MESSAGE = 'threads_new_message';
export const THREADS_NEW_MESSAGE_UNDO = 'threads_new_message_undo';
export const THREADS_LAST_MESSAGE = 'threads_last_message';
