import styled from 'styled-components';
import is from 'styled-is';

import { color, unit, screen } from 'core/v2/ui';

const PageContent = styled.section`
  flex: 1;
  padding: ${unit[3]};
  background-color: ${color.blue200};
  overflow: auto;

  ${screen.phone`
    padding: ${unit[4]};
  `};

  ${screen.tablet`
    padding: ${unit[5]};
  `};

  ${screen.desktop`
    padding: ${unit[5]} ${unit[7]};
  `};

  ${is('white')`
    background-color: ${color.white};
  `};

  ${is('noPad')`
    padding: 0 !important;
  `};

  ${is('scrollLock')`
    min-height: 100%;
  `};

  ${is('flexFit')`
    display: flex;
    width: 100%;
  `};

  ${is('noOverflow')`
    overflow: initial;
  `};
`;

export default PageContent;
