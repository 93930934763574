export const USERS_ENTITIES = 'users_entities';
export const PROFILES_ENTITIES = 'profile_entities';
export const SET_FRIENDS = 'set_friends';
export const SET_USER_SCRIPTS = 'set_user_scripts';
export const SET_NOTIFICATIONS = 'set_notifications';
export const SET_USERS = 'set_users';
export const SET_PRO_USERS = 'set_pro_users';
export const SET_PRO_REVIEWER_USERS = 'set_pro_reviewers_users';
export const UPDATE_USER = 'update_user';
export const SET_FRIENDSHIPS = 'set_friendships';
export const ADD_FRIENDSHIP = 'add_friendship';
export const REMOVE_FRIENDSHIP = 'remove_friendship';
export const ADD_FRIEND = 'add_friend';
export const REMOVE_FRIEND = 'remove_friend';
export const FRIEND_LOCATION_FILTER = 'friend_location_filter';
export const FRIEND_STATUS_FILTER = 'friend_status_filter';
export const ADD_NOTIFICATION = 'add_notification';
export const UPDATE_NOTIFICATION = 'update_notification';
export const DISMISS_NOTIFICATION = 'dismiss_notification';
