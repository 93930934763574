import React from 'react';
import PropTypes from 'prop-types';

import {
  WatermarkWrap,
  WatermarkIconWrap,
  WatermarkIcon,
  WatermarkTitle,
} from './styled';

const Watermark = ({ title, children, ...props }) => (
  <WatermarkWrap {...props}>
    <WatermarkIconWrap>
      <WatermarkIcon />
    </WatermarkIconWrap>
    {title && <WatermarkTitle>{title}</WatermarkTitle>}
    {children}
  </WatermarkWrap>
);

Watermark.propTypes = {
  noMargin: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
};

export default Watermark;
