import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/react';

import { store, persistor } from 'core/configStore';
import { authCheck } from 'features/auth/actions';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { MixpanelProvider } from './core/analytics';

import AppRoot from 'features/app/components/AppRoot/AppRoot';

// Setup Sentry bug tracking
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
});

store.dispatch(authCheck());

const Root = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <MixpanelProvider token={process.env.REACT_APP_MIXPANEL_TOKEN}>
        <AppRoot />
      </MixpanelProvider>
    </PersistGate>
  </Provider>
);

render(<Root />, document.getElementById('root'));

serviceWorkerRegistration.register();
