import merge from 'lodash.merge';
import mergeWith from 'lodash.mergewith';

const customizer = (objValue, srcValue) => {
  if (Array.isArray(objValue)) {
    objValue = srcValue;
    return objValue;
  } else {
    merge(objValue, srcValue);
  }
};

function removeByKey(myObj, deleteKey) {
  const { [deleteKey]: v, ...rest } = myObj;
  return rest;
}

export default (state = {}, action) => {
  switch (action.type) {
    case 'ENTITIES_SET':
      return mergeWith({}, state, action.payload, customizer);
    case 'ENTITIES_DELETE':
      if (state[action.payload.entityName]) {
        const entities = removeByKey(
          state[action.payload.entityName],
          action.payload.entityId
        );
        return {
          ...state,
          [action.payload.entityName]: entities,
        };
      }
      return state;
    default:
      return state;
  }
};
