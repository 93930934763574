import { createGlobalStyle } from 'styled-components';
import { normalize } from 'polished';

import { color, font } from 'core/v2/ui';

export const GlobalStyles = createGlobalStyle`
  ${normalize()}

  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  html,
  body {
    height: 100%;
  }

  body {
    font-family: ${font.sans};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #react-root {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  button, input, optgroup, select, textarea {
    font-family: inherit;
  }

  strong {
    font-weight: bold;
  }

  /* React Toast Notifcation Styles */
  .react-toast-notifications__container.react-toast-notifications__container {
    padding: 8px 16px 8px 8px;
  }

  .react-toast-notifications__toast {
    &.react-toast-notifications__toast {
      background-color: ${color.blue900};
      color: ${color.white};
    }

    .react-toast-notifications__toast__icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      background-color: transparent;
    }

    .react-toast-notifications__toast__icon {
      width: 18px;
      height: auto;
    }

    .react-toast-notifications__toast__content {
      display: flex;
      align-items: center;
      color: ${color.white};
    }

    &.react-toast-notifications__toast--success {
      .react-toast-notifications__toast__icon {
        color: ${color.green400};
      }
      .react-toast-notifications__toast__content {
        color: ${color.green200};
      }
    }

    &.react-toast-notifications__toast--error {
      .react-toast-notifications__toast__icon {
        color: ${color.red};
      }
      .react-toast-notifications__toast__content {
        color: ${color.red};
      }
    }
  }
`;
