export const SCRIPTS_ENTITIES = 'scripts_entities';

export const SET_SCRIPTS = 'set_scripts';
export const SET_MY_SCRIPTS = 'set_my_scripts';
export const SET_SINGLE_SCRIPT = 'set_single_scripts';
export const SET_NEW_SCRIPT = 'set_new_script';
export const ADD_SCRIPT = 'add_script';
export const SORT_SCRIPTS_BY = 'sort_scripts_by';
export const UPDATE_SCRIPTS = 'update_scripts';
export const UPDATE_SCRIPT = 'update_script';
export const DELETE_SCRIPT = 'delete_script';
export const SET_SCRIPT_RATING = 'set_script_rating';
export const SET_SCRIPT_SHARES = 'set_script_shares';
export const SET_SCRIPT_ACCESSES = 'set_script_accesses';
export const SET_SCRIPT_NOTIFICATIONS = 'set_script_notifications';
export const SET_PRO_REVIEW_SCRIPTS = 'set_pro_review_scripts';
