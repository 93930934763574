import { normalize } from 'normalizr';
import http from 'core/http';
import { group as groupSchema } from 'core/schema';
import { ADD_PENDING_GROUP } from 'features/groups/actionTypes';

export const APPROVE_ADMIN_GROUP = 'approve_admin_group';
export const DENY_ADMIN_GROUP = 'deny_admin_group';

/**
 * Fetch unpublished groups that need Admin approval
 * @param {*} data
 */
export const fetchAdminGroups = () => dispatch => {
  return http.get(`/api/v1/groups/unpublished`).then(res => {
    const data = normalize(res.data.data, [groupSchema]);
    dispatch({
      type: ADD_PENDING_GROUP,
      payload: data.result,
      entities: data.entities,
    });
  });
};

export const approveAdminGroup = groupId => dispatch =>
  http.post(`/api/v1/groups/${groupId}/approve-group`).then(() => {
    dispatch({
      type: APPROVE_ADMIN_GROUP,
      payload: groupId,
    });
  });

export const denyAdminGroup = groupId => dispatch =>
  http.post(`/api/v1/groups/${groupId}/deny-group`).then(() => {
    dispatch({
      type: DENY_ADMIN_GROUP,
      payload: groupId,
    });
  });
