import styled, { css } from 'styled-components';
import is from 'styled-is';

import { color, screen } from 'core/v2/ui';
import { ellipsis, getMarginBottom } from 'utils/styled';

const headingCss = css`
  display: flex;
  align-items: center;
  margin: 0;
  ${getMarginBottom};
  color: ${color.grey900};

  ${is('light')`
    color: ${color.white};
  `};

  ${is('ellipsis')`
    ${ellipsis};
  `};

  svg {
    margin-right: 24px;

    > path {
      fill: ${color.grey900};

      ${is('light')`
        fill: ${color.white};
      `};
    }
  }
`;

export const H1 = styled.h1`
  ${headingCss};

  font-size: 22px;
  font-weight: normal;
  line-height: 30px;

  ${screen.tablet`
    font-size: 26px;
    line-height: 36px;
  `}
`;

export const H2 = styled.h2`
  ${headingCss};

  font-size: 20px;
  font-weight: bold;
  line-height: 28px;

  ${screen.tablet`
    font-size: 24px;
    line-height: 32px;
  `}
`;

export const H3 = styled.h3`
  ${headingCss};

  font-size: 18px;
  font-weight: 600;
  line-height: 26px;

  ${screen.tablet`
    font-size: 24px;
    line-height: 32px;
  `}
`;
