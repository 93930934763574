import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { color } from 'core/v2/ui';

import { FlyoutButton } from 'generic/FlyoutNav/FlyoutNav';

const TEXT = {
  lg: `
    color: ${color.grey900};
    font-size: 14px;
  `,
  title: `
    color: ${color.grey900};
    font-size: 14px;
    font-weight: 600;
  `,
  date: `
    color: ${color.green};
    font-size: 12px;
    letter-spacing: 0.5px;
  `,
  type: `
    color: ${color.green};
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.5px;
    text-transform: capitalize;
  `,
};
const getText = ({ text }) => {
  return text ? TEXT[text] : '';
};

export const Cell = styled.div`
  ${getText};

  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const CellFlyoutButton = styled(FlyoutButton)`
  opacity: 0.3;
`;

export const CellLink = styled(Link)`
  color: ${color.grey900};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
