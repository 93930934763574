import { SET_REVIEWS } from './actionTypes';
import { AUTH_LOGOUT } from 'features/auth/actionTypes';

const initialState = {
  reviews: null,
};

const scripts = (state = initialState, action) => {
  switch (action.type) {
    case SET_REVIEWS:
      return {
        ...state,
        reviews:
          state.reviews && state.reviews.length > 0
            ? [...new Set(state.reviews.concat(action.payload))]
            : action.payload,
      };
    case AUTH_LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default scripts;
