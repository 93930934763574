import styled from 'styled-components';
import is from 'styled-is';
import PropTypes from 'prop-types';

import {
  getJustifyContent,
  getAlignItems,
  getMarginBottom,
  getFlex,
  getGap,
} from 'utils/styled';

const Flex = styled.div`
  display: flex;

  ${getJustifyContent};
  ${getAlignItems};
  ${getFlex};
  ${getMarginBottom};
  ${getGap('gap', 'grid-gap')};

  ${is('center')`
    justify-content: center;
    align-items: center;
  `};

  ${is('column')`
    flex-direction: column;
  `};

  ${is('relative')`
    position: relative;
  `};

  ${is('fit')`
    width: 100%;
    height: 100%;
  `};
`;

Flex.propTypes = {
  center: PropTypes.bool,
  column: PropTypes.bool,
  flex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  justifyBetween: PropTypes.bool,
  justifyAround: PropTypes.bool,
  justifyEvenly: PropTypes.bool,
  justifyCenter: PropTypes.bool,
  justifyEnd: PropTypes.bool,

  alignStretch: PropTypes.bool,
  alignCenter: PropTypes.bool,
  alignStart: PropTypes.bool,
  alignEnd: PropTypes.bool,
  alignBaseline: PropTypes.bool,
};

export default Flex;
