export const LOADED_LIBRARY_SCRIPTS = 'has_loaded_library';
export const LOADED_LIBRARY_CATEGORY = 'loaded_library_category';
export const SET_GENRE_FILTER = 'set_genre_filter';
export const SET_SCRIPT_TYPE_FILTER = 'set_script_type_filter';
export const SET_READING_LIST = 'set_reading_list';
export const SET_SHARED_WITH_ME = 'set_shared_with_me';
export const ADD_SCRIPT_READING_LIST = 'add_script_reading_list';
export const REMOVE_SCRIPT_READING_LIST = 'remove_script_reading_list';
export const ADD_SCRIPT_TO_FINISH = 'add_script_to_finish';
export const REMOVE_SCRIPT_TO_FINISH = 'remove_script_to_finish';
export const RESET_FILTERS = 'reset_filters';
