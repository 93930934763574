import { createStatic } from 'styled-components-breakpoint';
import { rgba } from 'polished';

export const color = {
  black: '#000',
  white: '#FFF',

  grey900: '#393D46',
  grey700: '#545966',
  grey: '#D7DBE5',
  grey100: '#F9F9FA',

  blue900: '#364560',
  blue800: '#3C5C81', // pro dark blue, sidebar
  blue700: '#5D6B8E',
  blue: '#4B74A4',
  blue400: '#758FAD',
  blue300: '#95C6FF', // pro light blue, sidebar
  blue200: '#EBF0F6',
  blue100: '#F8F9FC',

  green900: '#2C4251',
  green800: '#324B5D',
  green600: '#588383',
  green: '#60908F',
  green400: '#75ADAD',
  green200: '#AFC7C6',
  green100: '#F9FBFB',

  purple: '#8C4799',

  red: '#FF7E8E',

  online: '#7ED321',

  transparent: 'transparent',
};

export const shadow = {
  // centered
  c1: `0 0 4px ${rgba(color.green600, 0.1)}`, // button mini
  c2: `0 0 4px 2px ${rgba(color.grey900, 0.15)}`, // utility button, utility dropdown
  c3: '0 0 8px rgba(0, 0, 0, 0.05)', // script header

  // offset y
  y1: `0 2px 8px ${rgba(color.green600, 0.1)}`, // parent, parent thread
  y2: `0 3px 10px ${rgba(color.blue400, 0.25)}`, // flyout header
  y3: `0 8px 16px rgba(0, 0, 0, 0.04)`,

  // offset x and y
  xy1: `2px 2px 4px ${rgba(color.blue900, 0.15)}`, // switch
  xy2: `8px 8px 24px ${rgba(color.blue400, 0.15)}`, // flyout, note, button light
  xy3: `8px 8px 24px ${rgba(color.blue900, 0.3)}`, // modal, modal bg
  xy4: `8px 8px 24px ${rgba(color.blue900, 0.5)}`, // button dark

  // offset 2 to 1 for x and y
  x2y1: `16px 8px 16px ${rgba(color.blue, 0.13)}`, // card, script item
};

export const breakpoint = {
  phone: 420,

  // LARGER than the iPhone 6 Plus (which is 736px in landscape mode)
  tablet: 737,

  // LARGER than the iPad (which is 1024px in landscape mode)
  desktop: 1025,

  // Large screens
  wide: 1400,
};

// Responsive breakpoint helper
export const screen = createStatic(breakpoint);

export const unit = {
  0: 0,
  1: '8px',
  2: '16px',
  3: '24px',
  4: '32px',
  5: '40px',
  6: '48px',
  7: '72px',
  8: '80px',
  9: '108px',
  10: '144px',
};

export const height = {
  header: {
    default: '56px',
    tall: '64px',
  },
  pageHeader: {
    default: '108px',
    slim: '72px',
  },
  pageHeaderMobile: {
    default: '88px',
    slim: '64px',
  },
  card: {
    myScript: '202px',
    publicScript: '320px',
    group: '380px',
  },
  editor: {
    default: '100px',
  },
  chatSidebarMobile: {
    default: '160px',
  },
};

export const width = {
  header: {
    item: '50px',
    dropdown: {
      default: '200px',
      wide: '360px',
    },
  },
  sidebar: {
    default: '250px',
    compact: '82px',
    mobile: '300px',
  },
  card: {
    default: '240px',
  },
};

export const rounded = {
  sm: '2px',
  lg: '4px',
  xl: '24px',
};

export const font = {
  sans: '"Nunito Sans", Arial, "Helvetica Neue", Helvetica, sans-serif',
  serif: 'Georgia, Times, "Times New Roman", serif',
  mono:
    '"Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace',
};

export const fontWeight = {
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900,
};

export const zIndex = {
  header: 100,
  chatWidgets: 200,
  modalContainer: 9000,
  modal: 90001,
  tooltip: 10000,
};

export const duration = {
  short: '100ms',
  default: '300ms',
  long: '600ms',
};
