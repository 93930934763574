import { TOGGLE_COMPACT, SET_SETTINGS } from './actionTypes';
import { AUTH_LOGOUT } from 'features/auth/actionTypes';

const initialState = {
  isDesktopSidebarCompact: false,
  settings: {},
};

const app = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_COMPACT:
      return {
        ...state,
        isDesktopSidebarCompact: !state.isDesktopSidebarCompact,
      };
    case SET_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };
    case AUTH_LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default app;
