import React, { createContext, useContext, useEffect } from 'react';
import Mixpanel from 'mixpanel-browser';

const MixpanelContext = createContext();

/**
 * Mixpanel hook
 */
export const useMixpanel = () => useContext(MixpanelContext);

/**
 * Mixpanel HOC
 */
export const withMixpanel = WrappedComponent => {
  return class extends React.Component {
    render() {
      return (
        <MixpanelContext.Consumer>
          {mixpanel => <WrappedComponent {...this.props} mixpanel={mixpanel} />}
        </MixpanelContext.Consumer>
      );
    }
  };
};

/**
 * MixPanel context provider
 */
export const MixpanelProvider = ({ children, token }) => {
  console.log('Mixpanel token', token);

  useEffect(() => {
    if (!token) {
      console.warn('Mixpanel token is not provided. Tracking will be disabled.');
      return;
    }

    Mixpanel.init(token);
    Mixpanel.opt_in_tracking();

    if (!Mixpanel.has_opted_in_tracking()) {
      console.warn('Mixpanel tracking is disabled.');
      return;
    }

    return () => {
      Mixpanel.reset();
    };
  }, [token]);

  const trackEvent = (eventName, properties) => {
    if (!Mixpanel.has_opted_in_tracking()) {
      console.warn('Mixpanel tracking is disabled. Event not tracked.');
      return;
    }

    Mixpanel.track(eventName, properties);
  };

  const identifyUser = userId => {
    if (!Mixpanel.has_opted_in_tracking()) {
      console.warn('Mixpanel tracking is disabled. User identification not performed.');
      return;
    }

    Mixpanel.identify(userId);
  };

  const aliasUser = userId => {
    if (!Mixpanel.has_opted_in_tracking()) {
      console.warn('Mixpanel tracking is disabled. User aliasing not performed.');
      return;
    }

    Mixpanel.alias(userId);
  };

  const trackPageView = () => {
    if (!Mixpanel.has_opted_in_tracking()) {
      console.warn('Mixpanel tracking is disabled. Page view not tracked.');
      return;
    }

    Mixpanel.track('Page View');
  };

  return (
    <MixpanelContext.Provider
      value={{
        trackEvent,
        identifyUser,
        aliasUser,
        trackPageView,
      }}
    >
      {children}
    </MixpanelContext.Provider>
  );
};
