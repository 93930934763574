import React from 'react';
import PropTypes from 'prop-types';

import PageContent from 'generic/PageContent/PageContent';
import Watermark from 'generic/Watermark/Watermark';
import Flex from 'generic/Flex/Flex';

const AppLoading = ({ title }) => (
  <Flex fit>
    <PageContent>
      <Watermark noMargin title={title} />
    </PageContent>
  </Flex>
);

AppLoading.propTypes = {
  title: PropTypes.string,
};

AppLoading.defaultProps = {
  title: `Loading Writer's Spotlight - ScriptShare`,
};

export default AppLoading;
