import { normalize } from 'normalizr';
import * as ActionTypes from './actionTypes';
import http from 'core/http';
import { user as userSchema } from 'core/schema';

/**
 * Login the user
 * @param {*} credentials
 */
export const login = credentials => dispatch =>
  http
    .post('/api/v1/auth/login', credentials)
    .then(({ data }) => {
      const jwtToken = data.token;
      const user = data.user;
      const normalizedUser = normalize(user, userSchema);
      localStorage.setItem('jwt_token', jwtToken);
      http.defaults.headers.common['Authorization'] = `Bearer ${jwtToken}`;
      dispatch({
        type: ActionTypes.AUTH_LOGIN,
        payload: user,
        entities: normalizedUser.entities,
      });
    })
    .catch(err => {
      const statusCode = err.response.status;
      const data = {
        error: null,
        statusCode,
      };
      if (statusCode === 401 || statusCode === 422) {
        // status 401 means unauthorized
        // status 422 means unprocessable entity
        data.error = err.response.data.message;
      }
      return Promise.reject(data);
    });

/**
 * Handle user logout
 */
export const authLogout = () => dispatch => {
  localStorage.removeItem('jwt_token');
  localStorage.setItem('is_admin', false);
  dispatch({ type: ActionTypes.AUTH_LOGOUT });
};

/**
 * Check auth status of a user
 */
export const authCheck = () => (dispatch, getState) => {
  if (getState().auth.isAuthenticated) {
    http.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem(
      'jwt_token'
    )}`;
    dispatch({
      type: ActionTypes.AUTH_CHECK,
      payload: !!localStorage.getItem('jwt_token'),
    });
  }
};

/**
 * Update authenticated user
 */
export const authUpdate = () => (dispatch, getState) => {
  if (getState().auth.isAuthenticated) {
    http.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem(
      'jwt_token'
    )}`;
    dispatch({
      type: ActionTypes.AUTH_CHECK,
      payload: !!localStorage.getItem('jwt_token'),
    });
  }
};

/**
 * Reset password
 * @param {*} credentials
 */
export function resetPassword(credentials) {
  return () =>
    new Promise((resolve, reject) => {
      http
        .post('/api/password/email', credentials)
        .then(res => resolve(res.data))
        .catch(err => {
          const statusCode = err.response.status;
          const data = {
            error: null,
            statusCode,
          };
          if (statusCode === 401 || statusCode === 422) {
            // status 401 means unauthorized
            // status 422 means unprocessable entity
            data.error = err.response.data.message;
          }
          return reject(data);
        });
    });
}

/**
 * Update current user's password
 * @param {*} credentials
 */
export function updatePassword(credentials) {
  return () =>
    new Promise((resolve, reject) => {
      http
        .post('/api/password/reset', credentials)
        .then(res => {
          const statusCode = res.data.status;
          if (statusCode === 202) {
            const data = {
              error: res.data.message,
              statusCode,
            };
            return reject(data);
          }
          return resolve(res);
        })
        .catch(err => {
          const statusCode = err.response.status;
          const data = {
            error: null,
            statusCode,
          };
          if (statusCode === 401 || statusCode === 422) {
            // status 401 means unauthorized
            // status 422 means unprocessable entity
            data.error = err.response.data.message;
          }
          return reject(data);
        });
    });
}
