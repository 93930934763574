import {
  THREADS_SET_THREADS,
  THREADS_ADD_THREAD,
  THREADS_SET_MESSAGES,
  THREADS_ADD_MESSAGE,
  THREADS_OPEN_CHAT,
  THREADS_CLOSE_CHAT,
  THREADS_SET_ACTIVE,
  THREADS_UPDATE,
  THREADS_MARK_AS_READ,
  THREADS_MARK_AS_READ_UNDO,
  THREADS_NEW_MESSAGE,
  THREADS_NEW_MESSAGE_UNDO,
  THREADS_LAST_MESSAGE,
} from './actionTypes';
import { AUTH_LOGOUT } from 'features/auth/actionTypes';

const initialState = {
  threadIds: [],
  messageIds: [],
  activeThreadId: null,
  recentThreadIds: [],
};

const chat = (state = initialState, action) => {
  switch (action.type) {
    // ---
    case THREADS_SET_THREADS:
      return {
        ...state,
        threadIds: action.payload,
        activeThreadId: state.activeThreadId || action.payload[0],
      };
    case THREADS_ADD_THREAD:
      return {
        ...state,
        threadIds: [...new Set(state.threadIds.concat(action.payload))],
      };
    case THREADS_SET_MESSAGES:
      return {
        ...state,
        messageIds: [...new Set(state.messageIds.concat(action.payload))],
      };
    case THREADS_ADD_MESSAGE:
      return {
        ...state,
        messageIds: [...new Set(state.messageIds.concat(action.payload))],
      };
    // ---
    case THREADS_OPEN_CHAT:
      return {
        ...state,
        recentThreadIds: [...new Set(state.recentThreadIds.concat(action.payload))],
      };
    case THREADS_CLOSE_CHAT:
      return {
        ...state,
        recentThreadIds: state.recentThreadIds.filter(tid => tid !== action.payload),
      };
    case THREADS_SET_ACTIVE:
      return {
        ...state,
        activeThreadId: parseInt(action.payload, 10),
      };
    // case THREADS_MARK_AS_READ_UNDO:
    //   return {
    //     ...state,
    //     threadEntities: {
    //       ...state.threadEntities,
    //       [action.payload]: {
    //         ...state.threadEntities[action.payload],
    //         isUnread: true,
    //         unreadCount: 1,
    //       },
    //     },
    //   };
    // case THREADS_MARK_AS_READ:
    //   return {
    //     ...state,
    //     threadEntities: {
    //       ...state.threadEntities,
    //       [action.payload]: {
    //         ...state.threadEntities[action.payload],
    //         lastRead: new Date().toISOString(),
    //         isUnread: false,
    //         unreadCount: 0,
    //       },
    //     },
    //   };
    case THREADS_NEW_MESSAGE:
      return {
        ...state,
        threadEntities: {
          ...state.threadEntities,
          [action.payload.threadId]: {
            ...state.threadEntities[action.payload.threadId],
            latestMessage: action.payload.id,
          },
        },
        messageIds: [...new Set(state.messageIds.concat(action.payload.id))],
        messageEntities: {
          ...state.messageEntities,
          [action.payload.id]: action.payload,
        },
      };
    case THREADS_NEW_MESSAGE_UNDO:
      return {
        ...state,
        messageIds: state.messageIds.filter(mId => mId !== action.payload),
      };
    case THREADS_LAST_MESSAGE:
      return { ...state };
    case THREADS_UPDATE:
      return {
        ...state,
        threadEntities: {
          ...state.threadEntities,
          [action.payload.threadId]: {
            ...state.threadEntities[action.payload.threadId],
            latestMessage: action.payload.messageId,
            lastRead: new Date().toISOString(),
            isUnread: false,
            unreadCount: 0,
          },
        },
      };
    case AUTH_LOGOUT:
      return initialState;
    default:
      return { ...state };
  }
};

export default chat;
