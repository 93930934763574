import * as ActionTypes from './actionTypes';

const user = {
  id: null,
  name: null,
  email: null,
  createdAt: null,
  updatedAt: null,
};

const initialState = {
  isAuthenticated: false,
  isAdmin: false,
  isPro: false,
  isProReviewer: false,
  user,
  currentUserId: null,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.AUTH_LOGIN:
      // @TODO remove user once refactored
      return {
        ...state,
        user: action.payload,
        currentUserId: action.payload.id,
        isAuthenticated: true,
        isAdmin: action.payload.admin,
        isPro: action.payload.isPro,
        isProReviewer: action.payload.isProReviewer,
      };
    case ActionTypes.AUTH_CHECK:
      return { ...state, isAuthenticated: action.payload };
    case ActionTypes.AUTH_LOGOUT:
      return { ...state, isAuthenticated: false, user: user };
    case ActionTypes.AUTH_UPDATE:
      return { ...state, user: { ...state.user, ...action.payload } };
    default:
      return state;
  }
};

export default auth;
