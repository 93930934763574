import merge from 'lodash.merge';
import {
  USERS_ENTITIES,
  PROFILES_ENTITIES,
  SET_FRIENDS,
  SET_USER_SCRIPTS,
  SET_NOTIFICATIONS,
  SET_USERS,
  SET_FRIENDSHIPS,
  ADD_FRIENDSHIP,
  REMOVE_FRIENDSHIP,
  ADD_FRIEND,
  REMOVE_FRIEND,
  SET_PRO_USERS,
  FRIEND_LOCATION_FILTER,
  FRIEND_STATUS_FILTER,
  ADD_NOTIFICATION,
  UPDATE_NOTIFICATION,
  UPDATE_USER,
  SET_PRO_REVIEWER_USERS,
} from './actionTypes';
import { AUTH_LOGOUT } from 'features/auth/actionTypes';

const initialState = {
  byIds: {}, // All users entities
  allIds: [], // All users ids
  profileIds: [],
  scripts: [], // Current user scripts
  notifications: null, // Current user notifications
  friends: null, // Current user friends
  friendshipIds: [],
  friendshipEntities: {},
  location: null,
  status: null,
  users: [],
  proUsers: [],
  proReviewerUsers: [],
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case USERS_ENTITIES:
    case PROFILES_ENTITIES:
      return merge({}, state, action.payload);
    case SET_FRIENDS:
      return { ...state, friends: action.payload };
    case ADD_FRIEND:
      return {
        ...state,
        friends: [...new Set((state.friends || []).concat(action.payload))],
      };
    case REMOVE_FRIEND:
      return { ...state, friends: state.friends.filter(id => id !== action.payload) };
    case SET_USER_SCRIPTS:
      return { ...state, scripts: action.payload };
    case SET_NOTIFICATIONS:
      return { ...state, notifications: action.payload };
    case ADD_NOTIFICATION:
      return { ...state, notifications: [...state.notifications, action.payload] };
    case UPDATE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.map(n =>
          n.id === action.payload.id ? { ...n, ...action.payload } : n
        ),
      };
    case REMOVE_FRIENDSHIP:
      return {
        ...state,
        friendshipIds: state.friendshipIds.filter(fid => fid !== action.payload),
      };
    case ADD_FRIENDSHIP:
      return {
        ...state,
        friendshipIds: [...new Set((state.friendshipIds || []).concat(action.payload))],
      };
    case SET_FRIENDSHIPS:
      return {
        ...state,
        friendshipIds: action.payload.result,
        friendshipEntities: action.payload.entities.friendships,
      };
    case SET_USERS:
      return {
        ...state,
        users: [...new Set(state.users.concat(action.payload))],
      };
    case SET_PRO_USERS:
      return {
        ...state,
        proUsers: action.payload,
      };
    case SET_PRO_REVIEWER_USERS:
      return {
        ...state,
        proReviewerUsers: action.payload,
      };
    case UPDATE_USER:
      return { ...state, byIds: { ...state.byIds, ...action.payload } };
    case FRIEND_LOCATION_FILTER:
      return { ...state, location: action.payload };
    case FRIEND_STATUS_FILTER:
      return { ...state, status: action.payload };
    case AUTH_LOGOUT:
      return {
        ...state,
        scripts: [], // Current user scripts
        notifications: [], // Current user notifications
        friends: [], // Current user friends
        friendshipIds: [],
        friendshipEntities: {},
        location: null,
        status: null,
      };
    default:
      return state;
  }
};

export default users;
