import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const createHelpers = (width, height, css) => {
  // somehow sizes is ending up in markup, even if it is not a valid svg attribute
  // until we have a better solution, just render it empty, instead to '[Object object]'
  const sanitizeSizes = sizes =>
    Object.defineProperty(sizes, 'toString', {
      value: () => '',
      enumerable: false,
    });

  const getDimensions = (size, sizes) => {
    if (size && typeof size.width === 'number' && typeof size.height === 'number') {
      return size;
    }

    return size && sizes[size] ? sizes[size] : { width, height };
  };

  const getCss = (size, sizes, fillColor, fillColorRule, noStyles) => {
    if (noStyles) {
      return '';
    }

    const dimensions = getDimensions(size, sizes);
    const fillRule =
      fillColor && fillColorRule ? `${fillColorRule}{ fill: ${fillColor}; }` : '';

    return css`
      width: ${dimensions.width}px;
      height: ${dimensions.height}px;
      ${fillRule}
    `;
  };

  const propsToCss = ({ size, sizes, fillColor, fillColorRule, noStyles }) =>
    getCss(size, sizes, fillColor, fillColorRule, noStyles);

  return {
    getCss,
    getDimensions,
    propsToCss,
    sanitizeSizes,
  };
};

const width = '12';
const height = '12';
const viewBox = '0 0 12 12';

const { getDimensions, getCss, propsToCss, sanitizeSizes } = createHelpers(
  width,
  height,
  css
);

const sizes = sanitizeSizes({});

const Image = styled.svg`
  ${propsToCss}
`;

const children = (
  <Fragment>
    <path
      fill="#777D8A"
      fillRule="evenodd"
      d="M6.75 8.625a.36.36 0 01.264.111.36.36 0 01.111.264.36.36 0 01-.111.264.36.36 0 01-.264.111h-1.5a.36.36 0 01-.264-.111A.36.36 0 014.875 9a.36.36 0 01.111-.264.36.36 0 01.264-.111h.375v-3H5.25a.36.36 0 01-.264-.111.36.36 0 01-.111-.264.36.36 0 01.111-.264.36.36 0 01.264-.111H6a.36.36 0 01.264.111.36.36 0 01.111.264v3.375h.375zM6 4.125a.725.725 0 01-.533-.217.725.725 0 01-.217-.533c0-.21.072-.389.217-.533A.725.725 0 016 2.625c.21 0 .389.072.533.217a.725.725 0 01.217.533c0 .21-.072.389-.217.533A.725.725 0 016 4.125zM6 0a5.88 5.88 0 012.338.469 6.02 6.02 0 011.91 1.283 6.02 6.02 0 011.283 1.91c.313.73.469 1.51.469 2.338a5.88 5.88 0 01-.469 2.338 6.02 6.02 0 01-1.283 1.91 6.02 6.02 0 01-1.91 1.283A5.88 5.88 0 016 12a5.88 5.88 0 01-2.338-.469 6.02 6.02 0 01-1.91-1.283 6.02 6.02 0 01-1.283-1.91A5.88 5.88 0 010 6c0-.828.156-1.607.469-2.338a6.02 6.02 0 011.283-1.91A6.02 6.02 0 013.662.469 5.88 5.88 0 016 0zm0 11.262c.727 0 1.408-.139 2.045-.416a5.298 5.298 0 002.789-2.801A5.065 5.065 0 0011.25 6c0-.727-.139-1.408-.416-2.045a5.348 5.348 0 00-1.125-1.664 5.348 5.348 0 00-1.664-1.125A5.064 5.064 0 006 .75c-.727 0-1.408.139-2.045.416a5.348 5.348 0 00-1.664 1.125 5.348 5.348 0 00-1.125 1.664A5.064 5.064 0 00.75 6c0 .727.139 1.408.416 2.045a5.298 5.298 0 002.79 2.8A5.064 5.064 0 006 11.263z"
      key="key-0"
    />
  </Fragment>
);

const defaultProps = {
  children,
  viewBox,
  fillColor: null,
  fillColorRule: '&&& path, &&& use, &&& g',
  sizes,
  size: null,
};

Image.propTypes /* remove-proptypes */ = {
  fillColor: PropTypes.string,
  fillColorRule: PropTypes.string,
  viewBox: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      height: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
    }),
  ]),
  sizes: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
  }),
};

export default Object.assign(Image, {
  getDimensions,
  getCss,
  defaultProps,
  displayName: 'Icon_info',
});
