import { applyMiddleware, createStore, compose } from 'redux';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import ReduxThunk from 'redux-thunk';
// import logger from 'redux-logger';

import entitiesMiddleware from './entities';
import rootReducer from './reducers';

const migrations = {
  0: (state) => {
    return {
      ...state,
    }
  },
  1: (state) => {
    return {
      ...state,
      library: {
        ...state.library,
        category: {
          popular: null,
          latest: null,
          short: null,
          film: null,
          pilot: null,
        }
      }
    }
  }
}

const persistConfig = {
  key: 'root',
  storage,
  version: 1,
  migrate: createMigrate(migrations, { debug: false }),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Enable "Redux DevTools" in chrome and firefox
// @todo remove dev tools hook for prod?
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(ReduxThunk, entitiesMiddleware))
);
let persistor = persistStore(store);

export { store, persistor };
