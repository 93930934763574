export const REQUESTS_ENTITIES = 'requests_entities';

export const SET_OUTBOX_REQUESTS = 'set_outbox_requests';
export const SET_INBOX_REQUESTS = 'set_inbox_requests';
export const ADD_SHARE_REQUEST = 'add_share_request';
export const SET_SHARE_REQUESTS = 'set_share_requests';
export const REMOVE_SHARE_REQUEST = 'remove_share_request';
export const REMOVE_REQUEST = 'remove_request';
export const CANCEL_REQUEST = 'cancel_request';
export const UPDATE_REQUESTS = 'update_requests';
export const ADD_OUTBOX_REQUEST = 'add_outbox_request';
