import merge from 'lodash/merge';
import {
  SCRIPTS_ENTITIES,
  SET_NEW_SCRIPT,
  SET_SCRIPTS,
  ADD_SCRIPT,
  SORT_SCRIPTS_BY,
  UPDATE_SCRIPTS,
  SET_SINGLE_SCRIPT,
  SET_MY_SCRIPTS,
  DELETE_SCRIPT,
  UPDATE_SCRIPT,
  SET_SCRIPT_RATING,
  SET_SCRIPT_SHARES,
  SET_SCRIPT_ACCESSES,
  SET_SCRIPT_NOTIFICATIONS,
  SET_PRO_REVIEW_SCRIPTS,
} from './actionTypes';
import { REMOVE_REQUEST } from 'features/requests/actionTypes';
import { AUTH_LOGOUT } from 'features/auth/actionTypes';

const initialState = {
  newUpload: null,
  sortBy: 'recent',
  byIds: {},
  allIds: [],
  myScripts: null,
  scripts: [],
  proReviewScripts: null,
  ratings: {},
  shares: {},
  accesses: {},
  notifications: {},
};

const scripts = (state = initialState, action) => {
  switch (action.type) {
    case SCRIPTS_ENTITIES:
      return merge({}, state, action.payload);
    case SET_SCRIPTS:
      return {
        ...state,
        scripts: [...new Set(state.scripts.concat(action.payload))],
      };
    case SET_MY_SCRIPTS:
      return {
        ...state,
        myScripts: action.payload,
        scripts: [...new Set(state.scripts.concat(action.payload))],
      };
    case SET_NEW_SCRIPT:
      return { ...state, newUpload: action.payload };
    case UPDATE_SCRIPT:
      return { ...state };
    case SET_SCRIPT_RATING:
      return {
        ...state,
        ratings: { ...state.ratings, [action.payload.scriptId]: action.payload.ratings },
      };
    case SET_SCRIPT_NOTIFICATIONS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          [action.payload.scriptId]: action.payload.notifications,
        },
      };
    case SET_SCRIPT_SHARES:
      return {
        ...state,
        shares: {
          ...state.shares,
          [action.payload.scriptId]: action.payload.shares,
        },
      };
    case SET_SCRIPT_ACCESSES:
      return {
        ...state,
        accesses: {
          ...state.accesses,
          [action.payload.scriptId]: action.payload.accesses,
        },
      };
    case REMOVE_REQUEST:
      return {
        ...state,
        accesses: {
          ...state.accesses,
          [action.payload.scriptId]: state.accesses[action.payload.scriptId].filter(
            x => x !== action.payload.id
          ),
        },
      };
    case UPDATE_SCRIPTS:
      return {
        ...state,
        byIds: {
          ...state.byIds,
          [action.payload.id]: action.payload,
        },
        newUpload: null,
      };
    case SET_SINGLE_SCRIPT:
      return {
        ...state,
        scripts: [...new Set(state.scripts.concat(action.payload))],
      };
    case ADD_SCRIPT:
      return {
        ...state,
        myScripts: [...new Set(state.myScripts.concat(action.payload))],
        scripts: [...new Set(state.scripts.concat(action.payload))],
      };
    case SORT_SCRIPTS_BY:
      return { ...state, sortBy: action.payload };
    case DELETE_SCRIPT:
      return {
        ...state,
        scripts: state.scripts.filter(x => x != action.payload),
        myScripts: state.myScripts.filter(x => x != action.payload),
      };
    case SET_PRO_REVIEW_SCRIPTS:
      return { ...state, proReviewScripts: action.payload };
    case AUTH_LOGOUT:
      return { ...state, allIds: [] };
    default:
      return state;
  }
};

export default scripts;
