import axios from 'axios';
import humps from 'humps';

import { store } from 'core/configStore';
import { authLogout } from 'features/auth/actions';

// const token = document.head.querySelector('meta[name="csrf-token"]');
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
/**
 * Intercept request
 *
 * - Add auth token to it
 * - Decamelize keys of data sent to backend
 */
axios.interceptors.request.use(
  request => {
    if (localStorage.getItem('jwt_token') !== null) {
      request.headers['Authorization'] = `Bearer ${localStorage.getItem('jwt_token')}`;
    }

    if (request.method.match(/post|patch|put/i)) {
      if (
        request.headers['Content-Type'] !== 'multipart/form-data' &&
        request.headers['content-type'] !== 'multipart/form-data'
      ) {
        request.data = humps.decamelizeKeys(request.data);
      }
    }
    return request;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    if (
      response.headers['content-type'] === 'application/json' ||
      response.headers['Content-Type'] === 'application/json'
    ) {
      response.data = humps.camelizeKeys(response.data);
    }
    return response;
  },
  error => {
    // @todo add token refresh logic
    if (error.response && error.response.status === 401) {
      // @todo fix - when logging in and bad credentials are input
      // user is put through a redirect loop because of the `window.location` below.
      store.dispatch(authLogout());
      // window.location = '/login';
    }
    return Promise.reject(error);
  }
);

export default axios;
