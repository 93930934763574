import React from 'react';
import PropTypes from 'prop-types';

import { H1, H2, H3 } from './styled';

const Heading = ({ Icon, ...props }) => {
  let Component;
  switch (props.type) {
    case 1:
      Component = H1;
      break;

    case 2:
      Component = H2;
      break;

    case 3:
      Component = H3;
      break;

    default:
      Component = H1;
      break;
  }

  return (
    <Component {...props}>
      {Icon && <Icon light={props.light} />}
      {props.children}
    </Component>
  );
};

Heading.propTypes = {
  /** Heading type: h1, h2, h3 */
  type: PropTypes.oneOf([1, 2, 3]),
  /** White text */
  light: PropTypes.bool,
  /** Ellipsis long text */
  ellipsis: PropTypes.bool,
  /** Remove bottom margin */
  noMargin: PropTypes.bool,
  /** StyledSVG icon to show left of text */
  Icon: PropTypes.any,
  /** Heading text or node */
  children: PropTypes.node.isRequired,
};

export default Heading;
