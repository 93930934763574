import { css } from 'styled-components';

import { unit } from 'core/v2/ui';

export const darkTheme = (...args) => ({ theme }) =>
  theme && theme.name === 'dark' && css(...args);

export const isPro = (...args) => ({ theme }) => theme && theme.pro && css(...args);

export const isCompact = (...args) => ({ theme }) =>
  theme && theme.compact && css(...args);

export const isProp = (propName, value) => (...args) => props =>
  props[propName] && props[propName] === value && css(...args);

export const makeProp = (cssPropName, cssMap) => props => {
  const match = Object.keys(props).find(propName => cssMap[propName]);
  return match ? `${cssPropName}: ${cssMap[match]};` : '';
};

export const getJustifyContent = makeProp('justify-content', {
  justifyBetween: 'space-between',
  justifyAround: 'space-around',
  justifyEvenly: 'space-evenly',
  justifyCenter: 'center',
  justifyEnd: 'flex-end',
});

export const getAlignItems = makeProp('align-items', {
  alignStretch: 'stretch',
  alignCenter: 'center',
  alignStart: 'flex-start',
  alignEnd: 'flex-end',
  alignBaseline: 'baseline',
});

export const getMarginTop = ({ mT }) => mT > -1 && `margin-top: ${unit[mT]}`;
export const getMarginBottom = ({ mB }) => mB > -1 && `margin-bottom: ${unit[mB]}`;
export const getMarginLeft = ({ mL }) => mL > -1 && `margin-left: ${unit[mL]}`;

export const getPaddingY = ({ pY }) =>
  pY > -1 &&
  `
  padding-top: ${unit[pY]};
  padding-bottom: ${unit[pY]};
`;

export const getBold = ({ bold }) => bold && `font-weight: bold;`;

export const ellipsis = `
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const getFlex = ({ flex }) => flex && `flex: ${flex};`;

export const getGap = (gapType, gapRule) => props => {
  const gap = props[gapType];
  return gap ? `${gapRule}: ${unit[gap]};` : '';
};
