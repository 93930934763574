import { schema } from 'normalizr';

export const profile = new schema.Entity('profiles');

export const user = new schema.Entity('users');

export const draft = new schema.Entity('drafts');

export const comment = new schema.Entity('comments', {
  user,
});

export const like = new schema.Entity('likes', {
  user,
});

export const review = new schema.Entity('reviews', {
  user,
  draft,
});

export const proReview = new schema.Entity('proReviews', {
  reviewer: user,
});

export const script = new schema.Entity('scripts', {
  author: user,
  coWriters: [user],
  drafts: [draft],
  reviews: [review],
  proReview,
});

export const post = new schema.Entity('posts', {
  user,
  comments: [comment],
  likes: [like],
});

export const group = new schema.Entity('groups', {
  creator: user,
  scripts: [script],
  members: [user],
  posts: [post],
});

user.define({
  profile,
  scripts: [script],
  groups: [group],
  coWrites: [script],
});

export const request = new schema.Entity('requests', {
  scriptRequested: script,
  scriptSwapped: script,
  requestee: user,
  requestor: user,
});

export const friendship = new schema.Entity('friendships', {
  recipient: user,
  sender: user,
});

export const message = new schema.Entity('messages', {
  user: user,
});

export const thread = new schema.Entity('threads', {
  participants: [user],
  latestMessage: message,
  messages: [message],
});

export const list = new schema.Entity('lists', {
  scripts: [script],
  members: [user],
  user,
});

// --- Some examples below ---

// export const label = new schema.Entity('labels');

// export const milestone = new schema.Entity('milestones', {
//   creator: user
// });

// export const issue = new schema.Entity('issues', {
//   assignee: user,
//   assignees: [user],
//   labels: [label],
//   milestone,
//   user
// });

// export const pullRequest = new schema.Entity('pullRequests', {
//   assignee: user,
//   assignees: [user],
//   labels: [label],
//   milestone,
//   user
// });

// export const issueOrPullRequest = new schema.Array(
//   {
//     issues: issue,
//     pullRequests: pullRequest
//   },
//   (entity) => (entity.pull_request ? 'pullRequests' : 'issues')
// );
