import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const createHelpers = (width, height, css) => {
  // somehow sizes is ending up in markup, even if it is not a valid svg attribute
  // until we have a better solution, just render it empty, instead to '[Object object]'
  const sanitizeSizes = sizes =>
    Object.defineProperty(sizes, 'toString', {
      value: () => '',
      enumerable: false,
    });

  const getDimensions = (size, sizes) => {
    if (size && typeof size.width === 'number' && typeof size.height === 'number') {
      return size;
    }

    return size && sizes[size] ? sizes[size] : { width, height };
  };

  const getCss = (size, sizes, fillColor, fillColorRule, noStyles) => {
    if (noStyles) {
      return '';
    }

    const dimensions = getDimensions(size, sizes);
    const fillRule =
      fillColor && fillColorRule ? `${fillColorRule}{ fill: ${fillColor}; }` : '';

    return css`
      width: ${dimensions.width}px;
      height: ${dimensions.height}px;
      ${fillRule}
    `;
  };

  const propsToCss = ({ size, sizes, fillColor, fillColorRule, noStyles }) =>
    getCss(size, sizes, fillColor, fillColorRule, noStyles);

  return {
    getCss,
    getDimensions,
    propsToCss,
    sanitizeSizes,
  };
};

const width = '24';
const height = '24';
const viewBox = '0 0 24 24';

const { getDimensions, getCss, propsToCss, sanitizeSizes } = createHelpers(
  width,
  height,
  css
);

const sizes = sanitizeSizes({});

const Image = styled.svg`
  ${propsToCss}
`;

const children = (
  <Fragment>
    <path
      fill="#777D8A"
      fillRule="evenodd"
      d="M11.683 15.584c.544 0 1.016.2 1.416.6.4.4.6.872.6 1.416 0 .544-.2 1.016-.6 1.416-.4.4-.872.6-1.416.6-.544 0-1.016-.2-1.416-.6-.4-.4-.6-.872-.6-1.416 0-.544.2-1.016.6-1.416.4-.4.872-.6 1.416-.6zm0-6c.544 0 1.016.2 1.416.6.4.4.6.872.6 1.416 0 .544-.2 1.016-.6 1.416-.4.4-.872.6-1.416.6-.544 0-1.016-.2-1.416-.6-.4-.4-.6-.872-.6-1.416 0-.544.2-1.016.6-1.416.4-.4.872-.6 1.416-.6zm0-1.968c-.544 0-1.016-.2-1.416-.6-.4-.4-.6-.872-.6-1.416 0-.544.2-1.016.6-1.416.4-.4.872-.6 1.416-.6.544 0 1.016.2 1.416.6.4.4.6.872.6 1.416 0 .544-.2 1.016-.6 1.416-.4.4-.872.6-1.416.6z"
      key="key-0"
    />
  </Fragment>
);

const defaultProps = {
  children,
  viewBox,
  fillColor: null,
  fillColorRule: '&&& path, &&& use, &&& g',
  sizes,
  size: null,
};

Image.propTypes /* remove-proptypes */ = {
  fillColor: PropTypes.string,
  fillColorRule: PropTypes.string,
  viewBox: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      height: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
    }),
  ]),
  sizes: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
  }),
};

export default Object.assign(Image, {
  getDimensions,
  getCss,
  defaultProps,
  displayName: 'Icon_options',
});
