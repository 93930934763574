import React from 'react';
import PropTypes from 'prop-types';

import {
  FlyoutNavStyled,
  Overlay,
  Nav,
  Button,
  IconOptions,
  FlyoutContent,
  FlyoutFooter,
  FlyoutLink,
  FlyoutAction,
} from './styled';

const FlyoutButton = ({ active, dark, ...props }) => (
  <Button {...props}>
    <IconOptions active={active} dark={dark} />
  </Button>
);
FlyoutButton.propTypes = {
  active: PropTypes.bool,
  dark: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

const FlyoutNav = ({
  active,
  fit,
  row,
  navAutoHeight,
  largeButton,
  toggleNav,
  children,
}) => (
  <FlyoutNavStyled active={active} fit={fit} row={row}>
    <Overlay onClick={toggleNav} />
    <FlyoutButton
      absolute
      largeButton={largeButton}
      active={active}
      onClick={toggleNav}
    />
    <Nav navAutoHeight={navAutoHeight} largeButton={largeButton}>
      {children}
    </Nav>
  </FlyoutNavStyled>
);
FlyoutNav.propTypes = {
  active: PropTypes.bool,
  fit: PropTypes.bool,
  row: PropTypes.bool,
  navAutoHeight: PropTypes.bool,
  largeButton: PropTypes.bool,
  toggleNav: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export { FlyoutButton, FlyoutContent, FlyoutFooter, FlyoutLink, FlyoutAction };
export default FlyoutNav;
