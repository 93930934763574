import {
  LOADED_LIBRARY_SCRIPTS,
  LOADED_LIBRARY_CATEGORY,
  SET_GENRE_FILTER,
  SET_SCRIPT_TYPE_FILTER,
  SET_READING_LIST,
  SET_SHARED_WITH_ME,
  ADD_SCRIPT_READING_LIST,
  REMOVE_SCRIPT_READING_LIST,
  ADD_SCRIPT_TO_FINISH,
  REMOVE_SCRIPT_TO_FINISH,
  RESET_FILTERS,
} from 'features/library/actionTypes';
import { AUTH_LOGOUT } from 'features/auth/actionTypes';

const initialState = {
  popular: null,
  latest: null,
  short: null,
  film: null,
  pilot: null,
  category: {
    popular: null,
    latest: null,
    short: null,
    film: null,
    pilot: null,
  },
  pagination: {},
  // Shared with me - All scripts I have access to
  sharedWithMe: [],
  // Reading List - What I have put in my list
  readingList: [],
  // Script To Finish - Scripts I started to read
  toFinishList: [],
  genreFilter: '',
  typeFilter: '',
};

const library = (state = initialState, action) => {
  switch (action.type) {
    case LOADED_LIBRARY_SCRIPTS:
      return {
        ...state,
        popular: action.payload.popular,
        latest: action.payload.latest,
        short: action.payload.short,
        film: action.payload.film,
        pilot: action.payload.pilot,
        pagination: {},
      };
    case LOADED_LIBRARY_CATEGORY:
      return {
        ...state,
        category: {
          ...state.category,
          [action.payload.category]: action.payload.pagination.currentPage === 1
            ? action.payload.scripts
            : state[action.payload.category].concat(action.payload.scripts),
        },
        pagination: action.payload.pagination,
      };
    case SET_SHARED_WITH_ME:
      return {
        ...state,
        sharedWithMe: action.payload,
      };
    case SET_READING_LIST:
      return {
        ...state,
        readingList: action.payload,
      };
    case ADD_SCRIPT_READING_LIST:
      return { ...state, readingList: state.readingList.concat(action.payload) };
    case REMOVE_SCRIPT_READING_LIST:
      return {
        ...state,
        readingList: state.readingList.filter(scriptId => scriptId !== action.payload),
      };
    case SET_GENRE_FILTER:
      return { ...state, genreFilter: action.payload };
    case SET_SCRIPT_TYPE_FILTER:
      return { ...state, typeFilter: action.payload };
    case RESET_FILTERS:
      return { ...state, genreFilter: '', typeFilter: '' };
    case ADD_SCRIPT_TO_FINISH:
      return {
        ...state,
        toFinishList: [...new Set([action.payload, ...state.toFinishList])],
      };
    case REMOVE_SCRIPT_TO_FINISH:
      return {
        ...state,
        toFinishList: state.toFinishList.filter(scriptId => scriptId !== action.payload),
      };
    case AUTH_LOGOUT:
      return {
        ...state,
        sharedWithMe: [],
        readingList: [],
        toFinishList: [],
        genreFilter: '',
        typeFilter: '',
      };
    default:
      return state;
  }
};

export default library;
