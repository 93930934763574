import { SET_RESULTS, RESET_RESULTS } from './actionTypes';

const initialState = {
  groupIds: [],
  scriptIds: [],
  userIds: [],
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case RESET_RESULTS:
      return { ...state, groupIds: [], scriptIds: [], userIds: [] };
    case SET_RESULTS:
      return {
        ...state,
        groupIds: [...new Set(state.groupIds.concat(action.payload.groups))],
        scriptIds: [...new Set(state.scriptIds.concat(action.payload.scripts))],
        userIds: [...new Set(state.userIds.concat(action.payload.users))],
      };
    default:
      return state;
  }
};

export default users;
