import {
  SET_INBOX_REQUESTS,
  SET_OUTBOX_REQUESTS,
  SET_SHARE_REQUESTS,
  REMOVE_SHARE_REQUEST,
  UPDATE_REQUESTS,
  ADD_SHARE_REQUEST,
  CANCEL_REQUEST,
} from './actionTypes';
import { AUTH_LOGOUT } from '../auth/actionTypes';

const initialState = {
  sortBy: 'recent',
  requests: [],
  inbox: [],
  outbox: [],
  shareRequests: [],
};

const requests = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_REQUESTS:
      return {
        ...state,
        requests: [...new Set(state.requests.concat(action.payload))],
      };
    case SET_INBOX_REQUESTS:
      return {
        ...state,
        inbox: action.payload,
        requests: [...new Set(state.requests.concat(action.payload))],
      };
    case SET_OUTBOX_REQUESTS:
      return {
        ...state,
        outbox: action.payload,
        requests: [...new Set(state.requests.concat(action.payload))],
      };
    case SET_SHARE_REQUESTS:
      return {
        ...state,
        shareRequests: action.payload,
        requests: [...new Set(state.requests.concat(action.payload))],
      };
    case ADD_SHARE_REQUEST:
      return {
        ...state,
        shareRequests: [...new Set(state.shareRequests.concat(action.payload))],
        requests: [...new Set(state.requests.concat(action.payload))],
      };
    case CANCEL_REQUEST:
      return {
        ...state,
        requests: state.requests.filter(rid => rid !== action.payload),
        outbox: state.outbox.filter(rid => rid !== action.payload),
      }
    case REMOVE_SHARE_REQUEST:
      return {
        ...state,
        shareRequests: state.shareRequests.filter(x => x != action.payload),
        requests: state.requests.filter(x => x != action.payload),
      };
    case AUTH_LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default requests;
