import { SET_BILLING, UPDATE_CARD, UPDATE_PLAN, UPDATE_COUPON } from './actionTypes';
import { AUTH_LOGOUT } from 'features/auth/actionTypes';

const initialState = {
  card: {},
  plan: {},
  invoices: [],
  coupon: null,
};

const billing = (state = initialState, action) => {
  switch (action.type) {
    case SET_BILLING:
      return { ...state, ...action.payload };
    case UPDATE_CARD:
      return { ...state, card: action.payload };
    case UPDATE_PLAN:
      return { ...state, plan: action.payload };
    case UPDATE_COUPON:
      return { ...state, coupon: action.payload };
    case AUTH_LOGOUT:
      return { ...state, ...initialState };
    default:
      return state;
  }
};

export default billing;
