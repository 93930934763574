import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
import { ErrorBoundary } from '@sentry/react';

// Global styles
import { GlobalStyles } from 'styled';
import { ReactTableStyles } from 'generic/Table/react-table.css';

// Utilities
import Tooltip from 'generic/Tooltip/Tooltip';

// App error boundry
import AuthBoundary from 'features/auth/components/AuthBoundary/AuthBoundary';

// App loading placeholder
import AppLoading from 'features/app/components/AppLoading/AppLoading';

// Smooth scrolling polyfill
smoothscroll.polyfill();

/**
 * Authentication Routes
 */
const Login = lazy(() =>
  import(/* webpackChunkName: 'Login' */ 'features/auth/containers/LoginContainer')
);
const ForgotPassword = lazy(() =>
  import(
    /* webpackChunkName: 'ForgotPassword' */ 'features/auth/containers/ForgotPasswordContainer'
  )
);
const ResetPassword = lazy(() =>
  import(
    /* webpackChunkName: 'ResetPassword' */ 'features/auth/containers/ResetPasswordContainer'
  )
);

/**
 * App Container
 */
const App = lazy(() =>
  import(/* webpackChunkName: 'AppContainer' */ 'features/app/containers/AppContainer')
);

/**
 * Public Routes
 */
const Resume = lazy(() =>
  import(/* webpackChunkName: 'Resume' */ 'features/scripts/containers/ResumeContainer')
);

const AppLoader = () => (
  <ErrorBoundary fallback={props => <AuthBoundary {...props} />}>
    <GlobalStyles />
    <ReactTableStyles />
    <Suspense fallback={<AppLoading />}>
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/reset-password/:token/:email" component={ResetPassword} />
          <Route exact path="/" render={() => <Redirect to="/login" />} />
          <Route path="/resume/:id" component={Resume} />
          <Route render={() => <App />} />
        </Switch>
      </Router>
      <Tooltip />
    </Suspense>
  </ErrorBoundary>
);

export default AppLoader;
