import styled from 'styled-components';
import is from 'styled-is';

import { color, unit, screen } from 'core/v2/ui';

import WatermarkIconRaw from 'generic/Icon/logos/Logo_watermark';

export const WatermarkWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 100%;
  min-height: 200px;
  width: 100%;
  margin: ${unit[3]};

  ${is('noMargin')`
    margin: 0;
  `}

  ${screen.tablet`
    min-width: 403px;
    min-height: 390px;
    align-self: initial;
  `}
`;

export const WatermarkIconWrap = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const WatermarkIcon = styled(WatermarkIconRaw)`
  position: relative;
  max-height: 100%;
  max-width: 100%;
  /* opacity: 0.1; */
`;

export const WatermarkTitle = styled.div`
  position: relative;
  max-width: 600px;
  color: ${color.grey700};
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  text-align: center;
  z-index: 1;

  ${screen.tablet`
    font-size: 26px;
    line-height: 35px;
  `}
`;
