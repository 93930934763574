import { createGlobalStyle } from 'styled-components';

import { color, unit } from 'core/v2/ui';

import { CellFlyoutButton } from './styled';

export const ReactTableStyles = createGlobalStyle`
  .ReactTable.ReactTable {
    padding: 2px 4px;
    border: none;

    .rt-table {
      overflow: visible;
      background-color: transparent;
      border: none;
    }

    .rt-tr {
      min-height: 60px;
      align-items: center;
      margin-top: 1px;
      margin-bottom: 1px;
      background-color: ${color.green100};
      border: 1px solid ${color.grey};
    }

    .rt-thead {

      &.-header {
        box-shadow: none;
      }

      .rt-tr {
        background: ${color.white};
        align-items: stretch;
      }

      .rt-th,
      .rt-td {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        padding: ${unit[1]};
        color: ${color.blue400};
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 0.83px;
        text-transform: uppercase;
        border: none;
        outline: none;

        &.-sort-asc {
          box-shadow: inset 0 3px 0 0 ${color.blue400};
        }

        &.-sort-desc {
          box-shadow: inset 0 -3px 0 0 ${color.blue400};
        }
      }
    }

    .rt-tbody {
      overflow: initial;

      .rt-tr-group {
        border: none;
      }

      .rt-tr:hover {
        background-color: ${color.white};

        ${CellFlyoutButton} {
          opacity: 1;
        }
      }

      .rt-td {
        position: relative;
        padding: ${unit[1]};
        color: ${color.grey900};
        font-size: 12px;
        border: none;
        white-space: normal;
      }
    }

    .rt-noData {
      position: initial;
      transform: none;
    }
  }
`;
