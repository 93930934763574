import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const createHelpers = (width, height, css) => {
  // somehow sizes is ending up in markup, even if it is not a valid svg attribute
  // until we have a better solution, just render it empty, instead to '[Object object]'
  const sanitizeSizes = sizes =>
    Object.defineProperty(sizes, 'toString', {
      value: () => '',
      enumerable: false,
    });

  const getDimensions = (size, sizes) => {
    if (size && typeof size.width === 'number' && typeof size.height === 'number') {
      return size;
    }

    return size && sizes[size] ? sizes[size] : { width, height };
  };

  const getCss = (size, sizes, fillColor, fillColorRule, noStyles) => {
    if (noStyles) {
      return '';
    }

    const dimensions = getDimensions(size, sizes);
    const fillRule =
      fillColor && fillColorRule ? `${fillColorRule}{ fill: ${fillColor}; }` : '';

    return css`
      width: ${dimensions.width}px;
      height: ${dimensions.height}px;
      ${fillRule}
    `;
  };

  const propsToCss = ({ size, sizes, fillColor, fillColorRule, noStyles }) =>
    getCss(size, sizes, fillColor, fillColorRule, noStyles);

  return {
    getCss,
    getDimensions,
    propsToCss,
    sanitizeSizes,
  };
};

const width = '403';
const height = '390';
const viewBox = '0 0 403 390';

const { getDimensions, getCss, propsToCss, sanitizeSizes } = createHelpers(
  width,
  height,
  css
);

const sizes = sanitizeSizes({});

const Image = styled.svg`
  ${propsToCss}
`;

const children = (
  <Fragment>
    <defs key="key-0">
      <path id="s-3f46c2a6fb-a" d="M0 .009h392.991V390H0z" />
    </defs>
    <g fill="none" fillRule="evenodd" key="key-1">
      <g>
        <mask id="s-3f46c2a6fb-b" fill="#fff">
          <use xlinkHref="#s-3f46c2a6fb-a" />
        </mask>
        <path
          fill="#E2E7ED"
          d="M392.991 195.005C392.991 302.696 305.017 390 196.495 390 87.974 390 0 302.696 0 195.005 0 87.312 87.974.009 196.495.009c108.522 0 196.496 87.303 196.496 194.996"
          mask="url(#s-3f46c2a6fb-b)"
        />
      </g>
      <path
        fill="#EAEFF5"
        d="M20 206.765s23.81-6.626 28.77-4.251c3.749.087 36.695 87.647 36.695 87.647 10.06 26.113-2.794 40.243-20.41 8.941-21.721-38.6-36.009-90.967-45.055-92.337M47 117.336c4.462-4.7 87.066-8.681 90.491-4.712 10.073 16.836 76.268 168.824 76.268 168.824 22.231 50.93-19.715 90.173-50.755 30.475C141.501 272.853 80.695 121.151 47 117.336"
      />
      <path
        fill="#ECF1F6"
        d="M87.907 326s20.087-3.599 3.584-47.82c-16.502-44.224-40.007-98.384-40.007-98.384-19.878-45.935-11.82-83.308 27.004-11.924C113.221 227.479 145 305.012 145 305.012c-1.53 4.997-24.616 15.715-57.093 20.988M111.074 28.87l115.14 252.443c8.347 18.595 10.88 52.089-11.343 68.368l160.63-52.639s48.481-11.842 15.866-84.218C358.752 140.446 299.69 13.238 299.69 13.238L111.074 28.87z"
      />
    </g>
  </Fragment>
);

const defaultProps = {
  children,
  viewBox,
  fillColor: null,
  fillColorRule: '&&& path, &&& use, &&& g',
  sizes,
  size: null,
};

Image.propTypes /* remove-proptypes */ = {
  fillColor: PropTypes.string,
  fillColorRule: PropTypes.string,
  viewBox: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      height: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
    }),
  ]),
  sizes: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
  }),
};

export default Object.assign(Image, {
  getDimensions,
  getCss,
  defaultProps,
  displayName: 'Logo_watermark',
});
