import { omit } from 'utils/object';

import {
  FETCH_PRO_SCRIPTS,
  LOADED_PRO_SCRIPTS,
  LOADED_PRO_LISTS,
  UPDATE_PRO_LISTS,
  LOADED_FILTERED_PRO_SCRIPTS,
  LOADED_SHARED_PRO_SCRIPTS,
  SET_PRO_SCRIPTS_FILTERS,
  ADD_SAVED_PRO_SCRIPT,
  REMOVE_SAVED_PRO_SCRIPT,
  REMOVE_NOW_PRO_SCRIPT,
  ADD_REMOVED_PRO_SCRIPT,
  REMOVE_REMOVED_PRO_SCRIPT,
  SAVE_PRO_SCRIPTS,
  CANCEL_PRO_SCRIPTS,
  SET_BLACKLIST,
} from 'features/pro/actionTypes';
import { AUTH_LOGOUT } from 'features/auth/actionTypes';

const initialState = {
  lists: [],
  proFilters: null, // ModalProScriptsFiltersContainer.js for filters initial data shape
  filteredScripts: [], // array of script ids
  allScripts: [], // array of script ids
  sharedLists: [], // array of (shared) lists ids
  proScriptsAdd: {},
  proScriptsRemove: [],
  blacklist: [],
};

const pro = (state = initialState, action) => {
  switch (action.type) {
    case LOADED_PRO_SCRIPTS:
      return { ...state, allScripts: action.payload };
    case SET_BLACKLIST:
      return { ...state, blacklist: action.payload };
    case LOADED_FILTERED_PRO_SCRIPTS:
      return {
        ...state,
        filteredScripts: action.payload,
        allScripts: [...new Set(state.allScripts.concat(action.payload))],
      };
    case LOADED_PRO_LISTS:
      return { ...state, lists: action.payload };
    case LOADED_SHARED_PRO_SCRIPTS:
      return { ...state, sharedLists: action.payload };
    case UPDATE_PRO_LISTS:
      return { ...state, lists: [...new Set(state.lists.concat(action.payload))] };
    case SET_PRO_SCRIPTS_FILTERS:
      return { ...state, proFilters: action.payload };
    case ADD_SAVED_PRO_SCRIPT:
      return {
        ...state, // add to scripts to add
        proScriptsAdd: {
          ...state.proScriptsAdd,
          [action.payload.scriptId]: action.payload,
        }, // ensure not in scripts to remove
        proScriptsRemove: state.proScriptsRemove.filter(
          scriptId => scriptId !== action.payload.scriptId
        ),
      };
    case REMOVE_SAVED_PRO_SCRIPT:
      return {
        ...state, // remove from scripts to add
        proScriptsAdd: omit(state.proScriptsAdd, action.payload),
      };

    case REMOVE_NOW_PRO_SCRIPT:
      return {
        ...state, // remove from pro scripts list
        allScripts: state.allScripts.filter(scriptId => scriptId !== action.payload),
        filteredScripts: state.filteredScripts.filter(scriptId => scriptId !== action.payload),
        proScriptsAdd: omit(state.proScriptsAdd, action.payload),
        blacklist: [...new Set(state.blacklist.concat(action.payload))],
        proScriptsRemove: state.proScriptsRemove.filter(
          scriptId => scriptId !== action.payload
        ),
      };
    case ADD_REMOVED_PRO_SCRIPT:
      return {
        ...state,
        proScriptsAdd: omit(state.proScriptsAdd, action.payload),
        proScriptsRemove: [...new Set(state.proScriptsRemove.concat(action.payload))],
      };
    case REMOVE_REMOVED_PRO_SCRIPT:
      return {
        ...state,
        proScriptsAdd: omit(state.proScriptsAdd, action.payload),
        proScriptsRemove: state.proScriptsRemove.filter(
          scriptId => scriptId !== action.payload
        ),
      };

    case SAVE_PRO_SCRIPTS:
    case CANCEL_PRO_SCRIPTS:
      return { ...state, proScriptsAdd: {}, proScriptsRemove: [] };

    case AUTH_LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default pro;
