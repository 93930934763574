import { combineReducers } from 'redux';

import entities from 'core/entitiesReducers';
import app from 'features/app/reducer';
import auth from 'features/auth/reducer';
import billing from 'features/billing/reducer';
import chat from 'features/chat/reducer';
import modal from 'features/modal/reducer';
import groups from 'features/groups/reducer';
import scripts from 'features/scripts/reducer';
import search from 'features/search/reducer';
import requests from 'features/requests/reducer';
import reviews from 'features/reviews/reducer';
import users from 'features/users/reducer';
import library from 'features/library/reducer';
import pro from 'features/pro/reducer';
import proReviewer from 'features/proReviewer/reducer';

const rootReducer = combineReducers({
  entities,
  app,
  auth,
  billing,
  chat,
  modal,
  groups,
  scripts,
  search,
  requests,
  reviews,
  users,
  library,
  pro,
  proReviewer,
});

export default rootReducer;
